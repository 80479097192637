import { CloudImage } from "@fluidattacks/design";
import mixpanel from "mixpanel-browser";
import { useCallback } from "react";
import * as React from "react";

import { FormButton } from "./styles";
import type { IProvider } from "./types";

import { useAudit } from "hooks/use-audit";

const LoginProvider: React.FC<IProvider> = ({
  imageAlt,
  imageId,
  name,
  section,
  redirectUrl,
}: IProvider): JSX.Element => {
  const { addAuditEvent } = useAudit();
  const handleProviderLogin = useCallback((): void => {
    mixpanel.track(`${section} ${name}`);
    addAuditEvent(section, name);
    window.location.assign(redirectUrl);
  }, [addAuditEvent, name, redirectUrl, section]);

  return (
    <FormButton key={imageAlt} onClick={handleProviderLogin}>
      <CloudImage alt={imageAlt} publicId={imageId} width={"24px"} />
      {`Continue with ${name}`}
    </FormButton>
  );
};

export { LoginProvider };
