const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "236ef0b76e934a2c7fb8202318e80adcbf36323f";
const CI_COMMIT_SHORT_SHA = "236ef0b7";
const INTEGRATES_BUCKET_NAME = "views.fluidattacks.com";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
};
