const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "244879a1a5df4d1e7e6689291662f4f45964a64e";
const CI_COMMIT_SHORT_SHA = "244879a1";
const INTEGRATES_BUCKET_NAME = "views.fluidattacks.com";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
};
