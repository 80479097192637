import { CoralogixRum } from "@coralogix/browser";
import type {
  BeforeSendResult,
  EditableCxRumEvent,
} from "@coralogix/browser/src/types";

import { CI_COMMIT_SHORT_SHA } from "utils/ctx";

function filterCoralogix(event: EditableCxRumEvent): BeforeSendResult {
  const isUnknown = event.session_context.user_id === "";
  const isLocalhost =
    event.page_context.page_url.startsWith("https://localhost");
  const isEphemeral = event.page_context.page_url.includes(
    ".app.fluidattacks.com",
  );

  if (isUnknown || isLocalhost || isEphemeral) {
    return null;
  }

  return event;
}

const instrumentCoralogix = (): void => {
  CoralogixRum.init({
    application: "views",
    beforeSend: filterCoralogix,
    collectIPData: true,
    coralogixDomain: "US2",
    maskClass: "mask-data",
    maskInputTypes: ["password", "text", "number", "tel"],
    // eslint-disable-next-line camelcase
    public_key: "cxtp_VXUuXp9CF0v2Lwx5FIYy486cpey0UQ",
    sessionRecordingConfig: {
      autoStartSessionRecording: false,
      blockClass: "sr-block",
      enable: true,
      maskInputOptions: { password: true, tel: true },
      recordConsoleEvents: true,
    },
    version: CI_COMMIT_SHORT_SHA,
  });
};

export { instrumentCoralogix };
