import {
  Button,
  CloudImage,
  Container,
  Span,
  Text,
} from "@fluidattacks/design";
import isObject from "lodash/isObject";
import { Fragment, useCallback } from "react";
import { createPortal } from "react-dom";
import type { DefaultTheme } from "styled-components";
import { useTheme } from "styled-components";

import type { IPopUpMobileProps } from "./types";

const showDescription = (
  theme: DefaultTheme,
  description = "",
  highlightDescription: string[] | string = "",
): JSX.Element => {
  const highlightType = typeof highlightDescription === "string";
  const separatorRegex = highlightType
    ? ""
    : new RegExp(`(${highlightDescription.join("|")})`, "u");

  if (highlightDescription.length > 0) {
    return (
      <Text lineSpacing={1.5} size={"md"}>
        {highlightType ? (
          <Fragment>
            <Span
              color={theme.palette.gray["600"]}
              fontWeight={"bold"}
              lineSpacing={1.5}
              size={"md"}
            >
              {highlightDescription}
            </Span>
            {description}
          </Fragment>
        ) : (
          description
            .split(separatorRegex)
            .map((text, idx): JSX.Element | string =>
              highlightDescription.includes(text) ? (
                <Span
                  color={theme.palette.gray["600"]}
                  fontWeight={"bold"}
                  key={`${idx + 1}-bold`}
                  lineSpacing={1.5}
                  size={"md"}
                >
                  {text}
                </Span>
              ) : (
                text
              ),
            )
        )}
      </Text>
    );
  }

  return (
    <Text lineSpacing={1.5} size={"md"}>
      {description}
    </Text>
  );
};

const PopUpMobile = ({
  _portal = true,
  cancelButton,
  darkBackground = false,
  confirmButton,
  container,
  description,
  highlightDescription = "",
  image,
  title,
  width,
}: IPopUpMobileProps): JSX.Element => {
  const theme = useTheme();
  const {
    imageSrc,
    imageWidth = "100%",
    imageHeight = "175px",
    imageAlt,
  } = image;

  const handleCancel = useCallback((): void => {
    cancelButton?.onClick();
  }, [cancelButton]);
  const handleConfirm = useCallback((): void => {
    confirmButton?.onClick();
  }, [confirmButton]);

  const component = (
    <Container
      alignItems={"center"}
      bgColor={darkBackground ? "rgba(52, 64, 84, 70%)" : ""}
      display={"flex"}
      height={"100vh"}
      justify={"center"}
      position={_portal ? "fixed" : "absolute"}
      top={"0px"}
      width={"100%"}
      zIndex={99999}
    >
      <Container
        bgColor={theme.palette.white}
        border={`1px solid ${theme.palette.gray[200]}`}
        borderRadius={"8px"}
        display={"inline-flex"}
        flexDirection={"column"}
        pb={1.5}
        pl={1.5}
        position={"relative"}
        pr={1.5}
        pt={1.5}
        shadow={"lg"}
        width={width}
        zIndex={11}
      >
        <Text
          color={"black"}
          fontWeight={"bold"}
          lineSpacing={1.5}
          mb={1.5}
          size={"xl"}
          textAlign={"start"}
        >
          {title}
        </Text>
        <Container
          alignItems={"center"}
          height={imageHeight}
          justify={"center"}
          mb={1.5}
          mr={0}
        >
          <Container
            bgColor={theme.palette.gray[100]}
            left={"0"}
            position={"absolute"}
            textAlign={"center"}
            width={"100%"}
          >
            <CloudImage
              alt={imageAlt}
              height={imageHeight}
              publicId={imageSrc}
              width={imageWidth}
            />
          </Container>
        </Container>
        {showDescription(theme, description, highlightDescription)}
        <Container
          alignItems={"flex-start"}
          display={"flex"}
          gap={1}
          justify={"start"}
          mt={1}
        >
          {isObject(confirmButton) ? (
            <Button onClick={handleConfirm}>{confirmButton.text}</Button>
          ) : undefined}
          {isObject(cancelButton) ? (
            <Button onClick={handleCancel} variant={"tertiary"}>
              {cancelButton.text}
            </Button>
          ) : undefined}
        </Container>
      </Container>
    </Container>
  );

  if (_portal) {
    if (container) {
      return createPortal(component, container);
    }

    return createPortal(component, document.body);
  }

  return component;
};

export { PopUpMobile };
